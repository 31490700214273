import React, { useState, useEffect } from 'react';
import './ComingSoon.css'; // Stelle sicher, dass du eine CSS-Datei mit diesem Namen hast

const calculateTimeLeft = () => {
    const difference = +new Date('2025-01-01') - +new Date(); // Ziel-Datum
    let timeLeft = {};

    if (difference > 0) {
        timeLeft = {
            Tage: Math.floor(difference / (1000 * 60 * 60 * 24)),
            Stunden: Math.floor((difference / (1000 * 60 * 60)) % 24),
            Minuten: Math.floor((difference / 1000 / 60) % 60),
            Sekunden: Math.floor((difference / 1000) % 60),
        };
    }

    return timeLeft;
};

const ComingSoon = () => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (

        <div className="coming-soon-container">
            <h2>BarBeast</h2>
            <h1>Coming Soon!</h1>
            <div className="countdown">
                {Object.keys(timeLeft).map((interval) => (
                    <div key={interval}>
                        <span>{timeLeft[interval]}</span> {interval}{" "}
                    </div>
                ))}
            </div>
            <p></p>
            {/* Füge hier dein Anmeldeformular hinin */}
        </div>
    );
};

export default ComingSoon;
